import { useCallback, useEffect, useState } from "react";
import { Flex, Form, Image, Input, Radio, Tag, Typography } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { ClockCircleOutlined } from "@ant-design/icons";
import { debounce, map } from "lodash";
import dayjs from "dayjs";
import numeral from "numeral";

import { FALLBACK_IMAGE_PATH } from "../../constants/material";

import { useProductsAllList } from "../../hooks/apis/vending";

type ProductItem = {
  productCode: string;
  productName: string;
  brandName: string;
  productType: string;
  productInfo: string;
  productMemo: string;
  price: number;
  expireDate: number | null;
  fileName: string;
  photo: string;
};

export function ProductForm(props: {
  form: FormInstance;
  data?: {
    productCode: string;
    photo: string;
  };
  productsMapping: { [productCode: string]: ProductItem };
  areaId: string;
}) {
  const { data: { products = [] } = {} } = useProductsAllList();
  const [searchName, setSearchName] = useState("");

  useEffect(() => {
    props.form.resetFields();
  }, [props.form]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onKeywordInputChange = useCallback(
    debounce((e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchName(e.target.value);
    }, 500),
    [setSearchName]
  );

  return (
    <Form form={props.form} autoComplete="off" initialValues={{ productCode: "" }}>
      <div className="mb-3">
        <Input placeholder="請輸入欲搜尋的商品名稱" onChange={onKeywordInputChange} />
      </div>

      <Form.Item name="productCode" rules={[{ required: true, message: "請選擇商品" }]}>
        <Radio.Group>
          <Flex gap={10} wrap="wrap">
            {map(products, (item: ProductItem, index: number) => (
              <Flex
                vertical
                key={`${item.fileName}.${index}`}
                style={{
                  position: "relative",
                  width: 100,
                  display: (searchName ?? "") === "" || item.productName.indexOf(searchName) !== -1 ? "block" : "none",
                }}
              >
                <Image
                  width={100}
                  src={`${process.env.REACT_APP_API_SERVER_URL}/vending/areas/${props.areaId}/images/${item.fileName}`}
                  fallback={FALLBACK_IMAGE_PATH}
                />
                <Flex vertical gap={3}>
                  <Typography.Text>{props.productsMapping[item.productCode]?.productType}</Typography.Text>

                  <Typography.Text>{props.productsMapping[item.productCode]?.productName}</Typography.Text>

                  <Typography.Text style={{ textAlign: "right" }}>
                    {numeral(props.productsMapping[item.productCode]?.price).format("$0,0")}
                  </Typography.Text>

                  {props.productsMapping[item.productCode]?.expireDate && (
                    <Tag icon={<ClockCircleOutlined />} color="warning">
                      {dayjs(props.productsMapping[item.productCode]?.expireDate)?.format(`YYYY-MM-DD`)}
                    </Tag>
                  )}
                </Flex>

                <Radio style={{ position: "absolute", right: 0, top: 5 }} value={item.productCode}></Radio>
              </Flex>
            ))}
          </Flex>
        </Radio.Group>
      </Form.Item>
    </Form>
  );
}
