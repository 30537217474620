import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Select } from "antd";
import { FormInstance } from "antd/es/form/Form";
import Upload from "antd/es/upload/Upload";
import type { GetProp, UploadFile, UploadProps } from "antd";
import { useEffect } from "react";
import { MATERIAL_OPTIONS } from "../../constants/material";

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

export function MaterialForm(props: {
  form: FormInstance;
  data?: {
    images: [];
    types: number;
  };
}) {
  useEffect(() => {
    props.form.resetFields();
  }, [props.form]);

  const getFile = () => {
    return props.form.getFieldValue("images") || [];
  };

  return (
    <Form form={props.form} autoComplete="off" initialValues={{ images: [], types: 2 }}>
      <Form.Item label="用途" name="types" rules={[{ required: true, message: "請選擇用途" }]}>
        <Select placeholder="請選擇用途" options={MATERIAL_OPTIONS} disabled />
      </Form.Item>
      <Form.Item
        label="照片"
        name="images"
        valuePropName="images"
        getValueFromEvent={getFile}
        rules={[
          {
            required: true,
            message: "請選擇照片",
          },
          () => ({
            async validator(_, value) {
              let element: File;

              for (let index = 0; index < value.length; index++) {
                element = value[index];
                const imageInfo: any = await loadImage(element);

                if (element instanceof File) {
                  if (element.size > 1048576) {
                    return Promise.reject(new Error(`檔案太大，需小於 1MB（${element.name}）`));
                  } else if (imageInfo.width > 500 || imageInfo.height > 500) {
                    return Promise.reject(new Error(`尺寸有誤，需為 500x500 內（${element.name}）`));
                  }
                } else {
                  return Promise.reject(new Error(`檔案讀取有誤`));
                }
              }

              return Promise.resolve();
            },
          }),
        ]}
      >
        <Upload
          listType="picture"
          multiple
          beforeUpload={(file: File) => {
            const imageList = props.form.getFieldValue("images") || [];
            props.form.setFieldValue("images", imageList.concat(file));

            // 因為默認會有action 所以一定要 return false 不然會404
            return false;
          }}
          onPreview={onPreview}
          onRemove={(file: UploadFile) => {
            const imageList = props.form.getFieldValue("images") || [];
            props.form.setFieldValue(
              "images",
              imageList.filter((item: UploadFile) => item.uid !== file.uid)
            );
          }}
        >
          <Button icon={<UploadOutlined />}>選擇</Button>
        </Upload>
      </Form.Item>
    </Form>
  );
}

const onPreview = async (file: UploadFile) => {
  let src = file.url as string;
  if (!src) {
    src = await new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj as FileType);
      reader.onload = () => resolve(reader.result as string);
    });
  }
  const image = new Image();
  image.src = src;
  const imgWindow = window.open(src);
  imgWindow?.document.write(image.outerHTML);
};

const loadImage = (file: File) => {
  return new Promise((resolve, reject) => {
    let r = new FileReader();
    r.readAsDataURL(file);
    r.onload = (e: any) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        resolve({ width: img.width, height: img.height, src: img.src });
      };
    };
    r.onerror = () => {
      reject("檔案讀取失敗");
    };
  });
};
