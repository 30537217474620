import { useEffect } from "react";
import { map } from "lodash";
import dayjs from "dayjs";
import { Button, Card, Col, DatePicker, Flex, Form, Image, InputNumber, Layout, Row } from "antd";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import { FormProps, useForm } from "antd/es/form/Form";
import Input from "antd/es/input/Input";

import { FALLBACK_IMAGE_PATH } from "../../constants/material";

import { useCreateProduct, useImagesList } from "../../hooks/apis/vending";
import { useAreaStore } from "../../stores/useAreaStore";

const initialValues = {
  productName: "",
  brandName: "",
  productType: "",
  productInfo: "",
  productMemo: "",
  price: null,
  photo: "",
  expireDate: null,
  src: "",
};

type FieldType = {
  items: Array<{
    productName: string;
    brandName: string;
    productType: string;
    productInfo: string;
    productMemo: string;
    price: number;
    photo: string;
    expireDate: number | null;
    index: number;
    src: string;
  }>;
};

export function CreateProduct() {
  const areaStore = useAreaStore();
  const { data: { images = [] } = {} } = useImagesList();
  const { mutateAsync: createProduct } = useCreateProduct();

  const [form] = useForm<FieldType>();

  useEffect(() => {
    form.resetFields();
  }, [form]);

  const onFinish: FormProps<FieldType>["onFinish"] = values => {
    if (values.items.length) {
      const data = map(values.items, item => ({
        ...item,
        expireDate: item.expireDate ? dayjs(item.expireDate).valueOf() : null,
        index: item.index ?? 1,
      }));
      createProduct(data);
      form.resetFields();
    }
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      autoComplete="off"
      initialValues={{ items: [] }}
      style={{ height: "calc(100% - 84px)" }}
    >
      <div className="text-2xl font-bold">新增商品</div>

      <div className="flex flex-col items-end mb-5">
        <Button type="primary" htmlType="submit">
          儲存
        </Button>
      </div>

      <Form.List name="items">
        {(fields, { add, remove }, { errors }) => (
          <Layout hasSider style={{ height: "100%" }}>
            <Layout.Content style={{ overflowY: "auto" }}>
              <Flex gap={10} wrap="wrap">
                {map(images, (item: { fileName: string; type: number; vendingFileName: string }, index) => (
                  <Flex vertical key={`${item.fileName}.${index}`} style={{ position: "relative", width: 100 }}>
                    <Image
                      width={100}
                      src={`${process.env.REACT_APP_API_SERVER_URL}/vending/areas/${areaStore.selectedArea}/images/${item.fileName}`}
                      fallback={FALLBACK_IMAGE_PATH}
                    />

                    <Button
                      style={{ position: "absolute", top: 5, right: 5 }}
                      shape="circle"
                      icon={<PlusOutlined />}
                      onClick={() =>
                        add({
                          ...initialValues,
                          photo: item.vendingFileName,
                          src: item.fileName,
                        })
                      }
                    />
                  </Flex>
                ))}
              </Flex>
            </Layout.Content>
            <Layout.Content style={{ overflowY: "auto" }}>
              <Row gutter={[16, 16]}>
                {fields.map((field, index) => {
                  const imageSrc = form.getFieldValue(["items", field.name, "src"]);

                  return (
                    <Col span={12} key={field.key}>
                      <Card
                        title={`商品 - ${field.name + 1}`}
                        extra={
                          <CloseOutlined
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        }
                      >
                        <Flex justify="center" style={{ marginBottom: 10 }}>
                          <Image
                            width={100}
                            style={{ margin: "auto" }}
                            src={`${process.env.REACT_APP_API_SERVER_URL}/vending/areas/${areaStore.selectedArea}/images/${imageSrc}`}
                            fallback={FALLBACK_IMAGE_PATH}
                          />
                        </Flex>
                        <Form.Item
                          name={[field.name, "index"]}
                          label="排序"
                          rules={[{ required: true, message: "請輸入排序" }]}
                          validateTrigger={["onChange", "onBlur"]}
                        >
                          <InputNumber min={0} max={99999} style={{ width: "100%" }} />
                        </Form.Item>
                        <Form.Item
                          name={[field.name, "brandName"]}
                          label="品牌"
                          rules={[{ required: true, message: "請輸入品牌" }]}
                          validateTrigger={["onChange", "onBlur"]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name={[field.name, "productName"]}
                          label="名稱"
                          rules={[{ required: true, message: "請輸入名稱" }]}
                          validateTrigger={["onChange", "onBlur"]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name={[field.name, "productType"]}
                          label="類別"
                          rules={[{ required: true, message: "請輸入類別" }]}
                          validateTrigger={["onChange", "onBlur"]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name={[field.name, "productInfo"]}
                          label="簡述"
                          rules={[{ required: true, message: "請輸入簡述" }]}
                          validateTrigger={["onChange", "onBlur"]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name={[field.name, "productMemo"]}
                          label="備註"
                          rules={[{ required: true, message: "請輸入備註" }]}
                          validateTrigger={["onChange", "onBlur"]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name={[field.name, "price"]}
                          label="價錢"
                          rules={[
                            { required: true, message: "請輸入價錢" },
                            {
                              validator: async (rule, value) => {
                                if (!(value > 0)) {
                                  throw new Error("價錢需大於1");
                                } else if (/^[0-9]+\.[0-9]+$/.test(value)) {
                                  throw new Error("不允許小數");
                                } else if (!/^\d{1,9}$/.test(value)) {
                                  throw new Error("位數需小於9位");
                                }
                              },
                            },
                          ]}
                          validateTrigger={["onChange", "onBlur"]}
                        >
                          <Input type="number" />
                        </Form.Item>
                        <Form.Item name={[field.name, "expireDate"]} label="下架日">
                          <DatePicker placeholder="選擇下架日" minDate={dayjs().add(1, "day")} />
                        </Form.Item>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </Layout.Content>
          </Layout>
        )}
      </Form.List>
    </Form>
  );
}
