import { Button, Space, Tabs } from "antd";
import { useForm } from "antd/es/form/Form";
import { useEffect, useState } from "react";
import useModal from "antd/es/modal/useModal";
import { cloneDeep, filter, forEach, isEmpty, keys, map, reduce } from "lodash";

import { useMachineList, useProductsAllList, useUpdateMachineRestockSetting } from "../../hooks/apis/vending";
import { usePagination } from "../../hooks/usePagintation";

import { RestockSettingForm } from "./RestockSettingForm";
import { AmountForm } from "./AmountForm";
import { ProductForm } from "./ProductForm";
import { useAreaStore } from "../../stores/useAreaStore";

type ProductItem = {
  productCode: string;
  productName: string;
  brandName: string;
  productType: string;
  productInfo: string;
  productMemo: string;
  price: number;
  expireDate: number | null;
  fileName: string;
  photo: string;
};

type LayerItem = {
  layer: string;
  max: number;
  min: number;
  productCode: string;
  productId: number;
};

export function RestockSettingMachine() {
  const areaStore = useAreaStore();
  const pagination = usePagination({
    defaultPageSize: 30,
  });
  const { data: { machines = [] } = {} } = useMachineList({
    skip: pagination.skip,
    take: pagination.take,
  });
  const { data: { products = [] } = {} } = useProductsAllList();
  const { mutateAsync: updateRestockSetting } = useUpdateMachineRestockSetting();

  const [amountForm] = useForm<{
    operator: boolean;
    max: number;
  }>();
  const [productForm] = useForm<{
    productCode: string;
  }>();
  const [modal, contextHolder] = useModal();

  const [checkedList, setCheckedList] = useState<{
    machineId: string;
    data: {
      [layer: string]: LayerItem;
    };
  }>({ machineId: "", data: {} });
  const [productsMapping, setProductsMapping] = useState<{
    [productCode: string]: ProductItem;
  }>({});

  useEffect(() => {
    if (products.length) {
      const mapping = reduce(
        products,
        (accumulate: { [productCode: string]: ProductItem }, item: ProductItem) => {
          accumulate[item.productCode] = item;
          return accumulate;
        },
        {}
      );

      setProductsMapping(mapping);
    }
  }, [products]);

  return (
    <Space direction="vertical" className="w-full">
      {contextHolder}

      <div className="text-2xl font-bold">機台庫存設置</div>

      <Space direction="horizontal">
        <Button
          type="primary"
          disabled={!keys(checkedList.data).length}
          onClick={() => {
            modal.confirm({
              cancelText: "取消",
              content: <AmountForm form={amountForm} />,
              icon: null,
              okText: "確認",
              title: "批次調整數量",
              onOk: async () => {
                const values = await amountForm.validateFields();

                const thresholdValue = (values.operator ? 1 : -1) * values.max;
                let newValue = 1;

                await updateRestockSetting({
                  machineId: checkedList.machineId,
                  settings: map(checkedList.data, (item: LayerItem) => {
                    newValue = item.max + thresholdValue;
                    if (newValue < item.min) {
                      newValue = item.min;
                    } else if (newValue < 1) {
                      newValue = 1;
                    }

                    return {
                      ...item,
                      max: newValue,
                    };
                  }),
                });

                setCheckedList(checkedList => ({
                  ...checkedList,
                  data: {},
                }));
              },
            });
          }}
        >
          補貨
        </Button>

        {/* <Button
          type="primary"
          disabled={!keys(checkedList.data).length}
          onClick={async () => {
            await updateRestockSetting({
              machineId: checkedList.machineId,
              settings: map(
                checkedList.data,
                (item: { layer: string; max: number; min: number; productCode: string; productId: number }) => ({
                  ...item,
                  max: item.min > 0 ? item.min : 0,
                })
              ),
            });

            setCheckedList(checkedList => ({
              ...checkedList,
              data: {},
            }));
          }}
        >
          歸零
        </Button> */}

        <Button
          type="primary"
          disabled={!keys(checkedList.data).length}
          onClick={() => {
            modal.confirm({
              cancelText: "取消",
              content: (
                <ProductForm form={productForm} productsMapping={productsMapping} areaId={areaStore.selectedArea} />
              ),
              icon: null,
              okText: "確認",
              title: "換貨/上架",
              onOk: async () => {
                const values = await productForm.validateFields();

                await updateRestockSetting({
                  machineId: checkedList.machineId,
                  settings: map(
                    checkedList.data,
                    ({
                      productId,
                      ...item
                    }: {
                      layer: string;
                      max: number;
                      min: number;
                      productCode: string;
                      productId: number;
                    }) => {
                      return {
                        ...item,
                        productCode: values.productCode,
                        min: !item.productCode ? 1 : item.min < 1 ? 1 : item.min,
                        max: !item.productCode ? 1 : item.max,
                      };
                    }
                  ),
                });

                setCheckedList(checkedList => ({
                  ...checkedList,
                  data: {},
                }));
              },
            });
          }}
        >
          換貨/上架
        </Button>

        <Button
          type="primary"
          disabled={!keys(checkedList.data).length}
          onClick={async () => {
            const values = filter(checkedList.data, (item: { productCode: string }) => !!item.productCode);

            if (values.length) {
              await updateRestockSetting({
                machineId: checkedList.machineId,
                settings: map(
                  values,
                  (item: { layer: string; max: number; min: number; productCode: string; productId: number }) => {
                    return {
                      ...item,
                      productCode: "",
                    };
                  }
                ),
              });
            }

            setCheckedList(checkedList => ({
              ...checkedList,
              data: {},
            }));
          }}
        >
          下架
        </Button>
      </Space>

      <Tabs
        type="card"
        tabBarGutter={5}
        tabBarStyle={{ marginBottom: 0 }}
        onChange={machineId => {
          setCheckedList({
            machineId,
            data: {},
          });
        }}
        destroyInactiveTabPane
        items={map(machines, item => ({
          label: item.name,
          key: item.id,
          children: (
            <RestockSettingForm
              key={item.id}
              data={item}
              updateRestockSetting={updateRestockSetting}
              checkedList={checkedList.data}
              productsMapping={productsMapping}
              areaId={areaStore.selectedArea}
              onClickAllCheckbox={(allLayer: LayerItem[], nextStatus = false) => {
                setCheckedList(checkedList => {
                  const newCheckedList = cloneDeep(checkedList);
                  newCheckedList.machineId = item.id;

                  forEach(allLayer, item => {
                    if (item.layer) {
                      if (nextStatus) {
                        newCheckedList.data[item.layer] = item;
                      } else {
                        delete newCheckedList.data[item.layer];
                      }
                    }
                  });

                  return newCheckedList;
                });
              }}
              onClickCheckBox={(checkedTarget: {
                layer: string;
                max: number;
                min: number;
                productCode: string;
                productId: number;
              }) => {
                setCheckedList(checkedList => {
                  const newCheckedList = cloneDeep(checkedList);
                  newCheckedList.machineId = item.id;

                  if (isEmpty(newCheckedList.data[checkedTarget.layer])) {
                    newCheckedList.data[checkedTarget.layer] = checkedTarget;
                  } else {
                    delete newCheckedList.data[checkedTarget.layer];
                  }

                  return newCheckedList;
                });
              }}
            />
          ),
        }))}
      />
    </Space>
  );
}
