import { Button, Space, Table } from "antd";
import { useForm } from "antd/es/form/Form";
import { ColumnType } from "antd/es/table";
import useModal from "antd/es/modal/useModal";

import { usePagination } from "../../hooks/usePagintation";
import { useAreasList, useUpdateArea } from "../../hooks/apis/vending";

import { AreaForm } from "./AreaForm";

type RecordItem = { id?: string; name: string; companyId: string; token: string; supplierId: string };

export function AreaList() {
  const pagination = usePagination({
    defaultPageSize: 30,
  });

  const { data: { areas = [], total } = {}, isLoading: isMachinesLoading } = useAreasList({
    skip: pagination.skip,
    take: pagination.take,
  });
  const { mutateAsync: updateArea } = useUpdateArea();

  const [form] = useForm<RecordItem>();

  const [modal, contextHolder] = useModal();

  const columns: ColumnType<RecordItem>[] = [
    {
      title: "名稱",
      dataIndex: "name",
    },
    {
      title: "操作",
      width: 100,
      render: record => (
        <Space>
          <Button
            size="small"
            onClick={() => {
              modal.confirm({
                cancelText: "取消",
                content: <AreaForm form={form} data={record} />,
                icon: null,
                okText: "確認",
                title: "編輯場域",
                onOk: async () => {
                  const values = await form.validateFields();

                  return updateArea([values]);
                },
              });
            }}
          >
            編輯場域
          </Button>
        </Space>
      ),
    },
  ];

  const onCreateButtonClick = () => {
    modal.confirm({
      cancelText: "取消",
      content: <AreaForm form={form} />,
      icon: null,
      okText: "確認",
      title: "新增場域",
      onOk: async () => {
        const values = await form.validateFields();

        return updateArea([values]);
      },
    });
  };

  return (
    <Space direction="vertical" className="w-full">
      <div className="text-2xl font-bold">場域管理</div>

      {contextHolder}

      <Table
        dataSource={areas || []}
        columns={columns}
        loading={isMachinesLoading}
        pagination={{ ...pagination, total }}
        scroll={{ x: "max-content" }}
        size="small"
        rowKey="id"
        title={() => (
          <Button type="primary" onClick={onCreateButtonClick}>
            新增場域
          </Button>
        )}
      ></Table>
    </Space>
  );
}
