export const MATERIAL_OPTIONS = [
  // {
  //     label: "背景圖",
  //     value: 1,
  // },
  {
    label: "商品圖",
    value: 2,
  },
];

// export const FALLBACK_IMAGE_PATH = "https://placehold.co/500x500/png";
export const FALLBACK_IMAGE_PATH = "https://placehold.co/500x500?text=Not+Found";
export const NULLABLE_IMAGE_PATH = "https://placehold.co/500x500?text=Empty";
