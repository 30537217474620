import { useLogin } from "../../hooks/apis/auth";
import { Button, Form, Input } from "antd";
import { useForm } from "antd/es/form/Form";
import FormItem from "antd/es/form/FormItem";
import { GoogleCircleFilled } from "@ant-design/icons";

export function Login() {
  const { mutateAsync: login } = useLogin();

  const [form] = useForm();

  const onLoginButtonClick = async () => {
    const values = await form.validateFields();

    return login(values);
  };

  return (
    <div className="flex items-center justify-center h-full">
      <div className="flex flex-col items-stretch rounded-lg shadow-lg p-4 gap-4 ">
        <span className="text-2xl font-bold self-center">登入</span>
        <div className="flex gap-4">
          <div className="justify-center flex flex-col shrink-0 flex-grow basis-0">
            <Button
              className="w-full"
              icon={<GoogleCircleFilled />}
              onClick={() => {
                window.location.href = process.env.REACT_APP_API_SERVER_URL + "/auth/google/vending-callback";
              }}
            >
              Google 登入
            </Button>
          </div>
          <div className="justify-center shrink-0 border-l border-solid border-gray-200 border-r-0" />
          <Form form={form} className="shrink-0 flex-grow basis-0">
            <FormItem label="帳號" name="username" rules={[{ required: true, message: "請輸入帳號" }]}>
              <Input />
            </FormItem>
            <FormItem label="密碼" name="password" rules={[{ required: true, message: "請輸入密碼" }]}>
              <Input.Password />
            </FormItem>
            <FormItem>
              <Button className="w-full" type="primary" onClick={onLoginButtonClick} htmlType="submit">
                登入
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>
    </div>
  );
}
