import { useEffect } from "react";
import { Form, InputNumber, Select, Space } from "antd";
import { FormInstance } from "antd/es/form/Form";

const AMOUNT_OPERATOR_OPTIONS = [
  {
    label: "+",
    value: true,
  },
  {
    label: "-",
    value: false,
  },
];

export function AmountForm(props: { form: FormInstance }) {
  useEffect(() => {
    props.form.resetFields();
  }, [props.form]);

  return (
    <Form form={props.form} autoComplete="off" initialValues={{ max: 0, operator: true }}>
      <Form.Item label="數量">
        <Space.Compact>
          <Form.Item style={{ width: 60 }} name="operator">
            <Select options={AMOUNT_OPERATOR_OPTIONS} />
          </Form.Item>
          <Form.Item name="max" rules={[{ required: true, message: "請輸入數量" }]}>
            <InputNumber min={0} max={999999999} />
          </Form.Item>
        </Space.Compact>
      </Form.Item>
    </Form>
  );
}
