import { useEffect } from "react";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker, Form, InputNumber } from "antd";
import { FormInstance } from "antd/es/form/Form";
import Input from "antd/es/input/Input";

type FieldType = {
  productCode?: string;
  productName: string;
  brandName: string;
  productType: string;
  productInfo: string;
  productMemo: string;
  price: number;
  photo: string;
  expireDate: Dayjs | null;
  src: string;
};

export function InfoForm(props: { form: FormInstance; data: FieldType }) {
  useEffect(() => {
    props.form.resetFields();
  }, [props.form]);

  return (
    <Form form={props.form} autoComplete="off" initialValues={props.data}>
      <Form.Item name="productCode" hidden></Form.Item>
      <Form.Item name="index" label="排序" rules={[{ required: true, message: "請輸入排序" }]}>
        <InputNumber min={0} max={99999} />
      </Form.Item>
      <Form.Item
        name="productName"
        label="名稱"
        rules={[
          { required: true, message: "請輸入名稱" },
          {
            validator: async (rule, value) => {
              if (value && !/^[\u4e00-\u9fa5a-zA-Z0-9]+$/.test(value)) {
                throw new Error("只允許中文、英文或半型數字");
              }
            },
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="brandName" label="品牌" rules={[{ required: true, message: "請輸入品牌" }]}>
        <Input />
      </Form.Item>
      <Form.Item name="productType" label="類別" rules={[{ required: true, message: "請輸入類別" }]}>
        <Input />
      </Form.Item>
      <Form.Item name="productInfo" label="簡述" rules={[{ required: true, message: "請輸入簡述" }]}>
        <Input />
      </Form.Item>
      <Form.Item name="productMemo" label="備註" rules={[{ required: true, message: "請輸入備註" }]}>
        <Input />
      </Form.Item>
      <Form.Item
        name="price"
        label="價錢"
        rules={[
          { required: true, message: "請輸入價錢" },
          {
            validator: async (rule, value) => {
              value = String(Number(value));

              if (!(value > 0)) {
                throw new Error("價錢需大於1");
              } else if (/^[0-9]+\.[0-9]+$/.test(value)) {
                throw new Error("不允許小數");
              } else if (!/^\d{1,9}$/.test(value)) {
                throw new Error("位數需小於9位");
              }
            },
          },
        ]}
      >
        <Input type="number" />
      </Form.Item>
      <Form.Item name="expireDate" label="下架日">
        <DatePicker placeholder="選擇下架日" minDate={dayjs().add(1, "day")} />
      </Form.Item>
    </Form>
  );
}
