import { useEffect, useMemo, useState } from "react";
import { Button, Card, Checkbox, Divider, Flex, Image, InputNumber, Tag, Typography } from "antd";
import { cloneDeep, groupBy, isEmpty, keys, map, reduce, sortBy } from "lodash";
import { ClockCircleOutlined } from "@ant-design/icons";
import numeral from "numeral";
import dayjs from "dayjs";

import { FALLBACK_IMAGE_PATH, NULLABLE_IMAGE_PATH } from "../../constants/material";
import { useMachineRestockSetting } from "../../hooks/apis/vending";

type ProductItem = {
  productCode: string;
  productName: string;
  brandName: string;
  productType: string;
  productInfo: string;
  productMemo: string;
  price: number;
  expireDate: number | null;
  fileName: string;
  photo: string;
};

type FieldType = {
  id: string;
  code: string;
  name: string;
};

type MachineCodeOptions = Array<{
  label: string;
  value: string;
}>;

type CheckedList = {
  [layer: string]: {
    layer: string;
    max: number;
    min: number;
    productCode: string;
    productId: number;
  };
};

export function RestockSettingForm(props: {
  data: FieldType;
  machineCodeOptions?: MachineCodeOptions;
  updateRestockSetting: any;
  productsMapping: {
    [productCode: string]: ProductItem;
  };
  checkedList: CheckedList;
  onClickCheckBox: any;
  onClickAllCheckbox: any;
  areaId: string;
}) {
  const [shelfCount, setShelfCount] = useState<{ [layer: string]: number }>({});

  const { data: { settings = [] } = {}, isLoading: isMachinesLoading } = useMachineRestockSetting({
    machineId: props.data.id,
  });

  const groupingSettings = useMemo(() => {
    if (settings.length) {
      let sortedSettings = sortBy(settings, "layer");

      return groupBy(sortedSettings, item => item.layer.substr(0, 1));
    }
    return {};
  }, [settings]);

  useEffect(() => {
    if (settings.length > 0) {
      setShelfCount(
        reduce(
          settings,
          (accumulate: { [layer: string]: number }, item) => {
            accumulate[item.layer] = item.max;
            return accumulate;
          },
          {}
        )
      );
    }
  }, [settings]);

  return (
    <Card loading={isMachinesLoading}>
      {map(keys(groupingSettings), (groupingKey, index) => {
        return (
          <div key={groupingKey} className="flex flex-wrap">
            {index !== 0 && <Divider type="horizontal" style={{ borderWidth: 5, borderColor: "#000" }} />}

            <div className="flex gap-2 mb-2" style={{ width: "100%" }}>
              <Button
                type="default"
                onClick={() => {
                  props.onClickAllCheckbox(groupingSettings[groupingKey], true);
                }}
              >
                全選
              </Button>
              <Button
                type="default"
                onClick={() => {
                  props.onClickAllCheckbox(groupingSettings[groupingKey], false);
                }}
              >
                全不選
              </Button>
            </div>
            {map(
              groupingSettings[groupingKey],
              (item: { layer: string; max: number; min: number; productCode: string; productId: number }) => {
                if (/^A(.*)[5-8]$/.test(item.layer)) {
                  // TODO: 前端先寫死
                  return null;
                }
                return (
                  <Card.Grid
                    key={item.layer}
                    className="flex flex-col"
                    style={{ position: "relative", width: 135, padding: 15 }}
                  >
                    <Checkbox
                      className="my-1"
                      checked={props.checkedList && item.layer ? !isEmpty(props.checkedList[item.layer]) : false}
                      onChange={() => props.onClickCheckBox(item)}
                    >
                      {item.layer}
                    </Checkbox>

                    <Image
                      width={105}
                      height={105}
                      src={`${process.env.REACT_APP_API_SERVER_URL}/vending/areas/${props.areaId}/images/${props.productsMapping[item.productCode]?.fileName}`}
                      fallback={item.productCode ? FALLBACK_IMAGE_PATH : NULLABLE_IMAGE_PATH}
                    />

                    {!!item.productCode && (
                      <Flex vertical gap={3}>
                        <Typography.Text>{props.productsMapping[item.productCode]?.productType}</Typography.Text>

                        <Typography.Text>{props.productsMapping[item.productCode]?.productName}</Typography.Text>

                        <Typography.Text style={{ textAlign: "right", color: "#f00" }}>
                          {numeral(props.productsMapping[item.productCode]?.price).format("$0,0")}
                        </Typography.Text>

                        <InputNumber
                          addonBefore="數量"
                          size="small"
                          min={item.min || 1}
                          value={shelfCount[item.layer] ?? 0}
                          onChange={value =>
                            setShelfCount(shelfCount => {
                              const newShelfCount = cloneDeep(shelfCount);
                              newShelfCount[item.layer] = Number(value);
                              return newShelfCount;
                            })
                          }
                          onBlur={() => {
                            props.updateRestockSetting({
                              machineId: props.data.id,
                              settings: [
                                {
                                  ...item,
                                  max: shelfCount[item.layer],
                                },
                              ],
                            });
                          }}
                        />

                        {props.productsMapping[item.productCode]?.expireDate && (
                          <Tag icon={<ClockCircleOutlined />} color="warning">
                            {dayjs(props.productsMapping[item.productCode]?.expireDate)?.format(`YYYY-MM-DD`)}
                          </Tag>
                        )}
                      </Flex>
                    )}
                  </Card.Grid>
                );
              }
            )}
          </div>
        );
      })}
    </Card>
  );
}
