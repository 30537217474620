import { Button, Flex, Image, Space, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import useModal from "antd/es/modal/useModal";
import { map } from "lodash";

import { FALLBACK_IMAGE_PATH } from "../../constants/material";

import { useImagesList, useUploadImage } from "../../hooks/apis/vending";
import { useAreaStore } from "../../stores/useAreaStore";
import { MaterialForm } from "./MaterialForm";

export function MaterialList() {
  const areaStore = useAreaStore();
  const { data: { images = [] } = {} } = useImagesList();
  const { mutateAsync: uploadImage } = useUploadImage();

  const [form] = useForm<{
    images: any;
    types: number;
  }>();
  const [modal, contextHolder] = useModal();

  const onUploadButtonClick = () => {
    modal.confirm({
      cancelText: "取消",
      content: <MaterialForm form={form} />,
      icon: null,
      okText: "確認",
      title: "新增素材",
      onOk: async () => {
        const values = await form.validateFields();

        return uploadImage(values);
      },
    });
  };

  return (
    <Space direction="vertical" className="w-full">
      {contextHolder}

      <div className="text-2xl font-bold">素材管理</div>

      <Button type="primary" onClick={onUploadButtonClick}>
        新增素材
      </Button>

      <Flex gap={10} wrap="wrap">
        {map(images, (item: { fileName: string; type: number; vendingFileName: string }, index) => (
          <Flex vertical key={`${item.fileName}.${index}`} style={{ width: 100 }}>
            <Image
              width={100}
              src={`${process.env.REACT_APP_API_SERVER_URL}/vending/areas/${areaStore.selectedArea}/images/${item.fileName}`}
              fallback={FALLBACK_IMAGE_PATH}
            />
            <Typography.Text>{item.fileName}</Typography.Text>
          </Flex>
        ))}
      </Flex>
    </Space>
  );
}
