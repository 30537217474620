import { useEffect } from "react";
import { Form } from "antd";
import { FormInstance } from "antd/es/form/Form";
import Input from "antd/es/input/Input";

type FieldType = {
  id: string;
  name: string;
  companyId: string;
  token: string;
  supplierId: string;
};

export function AreaForm(props: { form: FormInstance; data?: FieldType }) {
  useEffect(() => {
    props.form.resetFields();
  }, [props.form]);

  return (
    <Form form={props.form} autoComplete="off" initialValues={props.data}>
      <Form.Item name="id" hidden></Form.Item>
      <Form.Item name="name" label="名稱" rules={[{ required: true, message: "請輸入名稱" }]}>
        <Input />
      </Form.Item>
      <Form.Item name="companyId" label="後台編碼" rules={[{ required: true, message: "請輸入後台編碼" }]}>
        <Input />
      </Form.Item>
      <Form.Item name="token" label="Token" rules={[{ required: true, message: "請輸入Token" }]}>
        <Input />
      </Form.Item>
      <Form.Item name="supplierId" label="供應商號" rules={[{ required: true, message: "請輸入供應商號" }]}>
        <Input />
      </Form.Item>
    </Form>
  );
}
