import { create } from "zustand";

type AreaStore = {
  setArea: (token: string) => void;
  selectedArea: string;
};

export const useAreaStore = create<AreaStore>()(set => ({
  setArea: (id: string) => set({ selectedArea: id }),
  selectedArea: "",
}));
