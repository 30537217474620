import { useEffect } from "react";
import { Flex, Form, Image, Radio } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { map } from "lodash";

import { FALLBACK_IMAGE_PATH } from "../../constants/material";

import { useImagesList } from "../../hooks/apis/vending";

export function ImageListForm(props: {
  form: FormInstance;
  data: {
    productCode: string;
    photo: string;
  };
  areaId: string;
}) {
  const { data: { images = [] } = {} } = useImagesList();

  useEffect(() => {
    props.form.resetFields();
  }, [props.form]);

  return (
    <Form form={props.form} autoComplete="off" initialValues={props.data}>
      <Form.Item name="productCode" hidden></Form.Item>
      <Form.Item name="photo">
        <Radio.Group>
          <Flex gap={10} wrap="wrap">
            {map(images, (item: { fileName: string; type: number; vendingFileName: string }, index) => (
              <Flex vertical key={`${item.fileName}.${index}`} style={{ position: "relative", width: 100 }}>
                <Image
                  width={100}
                  src={`${process.env.REACT_APP_API_SERVER_URL}/vending/areas/${props.areaId}/images/${item.fileName}`}
                  fallback={FALLBACK_IMAGE_PATH}
                />

                <Radio style={{ position: "absolute", right: 0, top: 5 }} value={item.vendingFileName}></Radio>
              </Flex>
            ))}
          </Flex>
        </Radio.Group>
      </Form.Item>
    </Form>
  );
}
